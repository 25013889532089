.App {
  text-align: center;
  /* background-image: url('./background_orange_waves2.svg'); */
  /* background-color: #000D18; */
  /* background-color: #001320; */
  height: 100%;
  /* font-family: 'Consolas', 'Cascadia Mono'; */
}

.ContactList-container {
  display: flex;
  flex-direction: column;
}

.ContactList-letter-group {
  display: flex;
  flex-direction: column;
}

.ContactList-letter-group-contacts {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
}

/* @media screen and (max-width: 821px) {
  .ContactList-letter-group-contacts {
    justify-content: center;
  }
} */

.ContactCard-container {
  background-color: #222222;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  height: 80px;
  border-radius: 10px;
  margin: 3px;
}

.ContactCard-container:hover {
  background-color: azure;
  transition: all 0.3s ease-in-out;
  /* cursor: pointer; */
}

.ContactCard-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: large;
  color: #68CDED;
  padding: 5px 0;
}

.ContactCard-modal {
  color: #68CDED;
  margin-left: auto;
  padding: 7px;
  margin-right: 5px;
}

.ContactCard-modal:hover {
  cursor: pointer;
}

.ContactCard-modal-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.header {
  font-weight: bold;
}

.mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.let {
  color: #3A5086;
}